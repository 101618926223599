<template lang="pug">
.Help
  .banderaSvgMobile
  Modal(:header="true" :goBack="goBack")
    template(#body)
      .d-flex.justify-content-center.align-items-start
        img(
          src="@/assets/mobileFSFB/cancel_data.png"
          alt="confirm_data"
        ).image-confirm_data.pl-3
        h2.text-confirm_data.ml-3: strong Bienvenido, eres un usuario nuevo
      article.pl-2.pt-3.containerData.mb-4.pb-1
        p.mb-1.containerFontModal
          strong.strongFont Documento:
          span.ml-1 {{ number }}
        p.mb-1.containerFontModal
          strong.strongFont Número:
          span.ml-1 ******{{ typeof document == 'number' ? String(document).substring(4) : document.substring(4) }}
      .d-flex.justify-content-between.pt-3.pb-4
        button(
          @click="goBack"
        ).buttonBack.px-4.py-2 Reingresar
        button(
          @click="onContinue"
        ).buttonConfirm.px-4.py-2
          span Confirmar
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Help",

  components: {
    Modal: () => import("./components/Modal.vue")
  },

  computed: {
    ...mapState({
      docInfo: state => state.mobileFSFB.docInfo,
      tipide: state => state.mobileFSFB.tipide
    }),

    document() {
      return this.docInfo?.NumeroIdentificacion || "";
    },

    number() {
      return (
        this.tipide.find(
          tipideItem => tipideItem.value === this.docInfo.TipoIdentificacion
        ).text || "No hay número"
      );
    }
  },

  methods: {
    ...mapActions({
      goToView: "mobileFSFB/goToView"
    }),

    goBack() {
      this.goToView({ view: "Home" });
    },

    onContinue() {
      this.goToView({ view: "Procedure" });
    }
  }
};
</script>

<style lang="scss">
.Help {
  height: 100%;
}

.blueText {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #69cdfe;
}

.logoHelp {
  top: 0;
  left: auto;
  transform: none;
  margin-top: 56px;
  margin-bottom: 20%;
  position: relative;
}

.text-confirm_data {
  width: 177px;
}

.boxDarker {
  background: #202d78;
  border-radius: 20px;
}

@media screen and (max-width: 330px) {
  .buttonConfirm {
    padding: 0.5rem 1rem !important;
  }
}
</style>
